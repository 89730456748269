import * as React from "react"
import Layout from "../components/layout"
import "../global.css"
import develop from "../assets/Component 26.png"
import { Helmet } from "react-helmet"

export default function Software() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | Software development</title>
        <link rel="canonical" href="https://aikate.eu/softwaredevelopment" />
        <meta
          name="Software development"
          content="Software development, IoT solutions"
        />
        <meta name="description" content="aiKATE development process"></meta>
      </Helmet>
      <div className="px-4 py-16  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="pt-20 pr-8 grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <h2 className="mb-4 text-5xl sm:text-4xl lg:text-7xl font-extrabold leading-none">
              Software development
            </h2>
            <p className="mb-6 text-3xl pt-4 text-white">
              We develop applications specifically designed for a particular
              purpose, department, or company. The software can incorporate
              features from other software programs or be written and designed
              to meet the client's specific business processes.
            </p>
            <div className="flex items-center space-x-4"></div>
          </div>
          <div>
            <img
              className="object-cover w-400 h-400 md:pl-10 sm:h-96"
              src={develop}
              alt="development"
            />
          </div>
        </div>
      </div>

      <div className="px-4 py-16 items-center mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
          <div className="grid gap-6 md:grid-cols-2 md:col-span-2 lg:col-span-5">
            <div className="card2 rounded lg:p-5 lg:transition lg:duration-300 lg:hover:text-gray-900 lg:hover:bg-[#2D3450]">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-4 h-4 mr-2 text-xl font-bold text-blue-300  rounded">
                  1
                </span>
                <p className="text-3xl font-bold text-blue-300 sm:text-xl">
                  Idea
                </p>
              </div>
              <p className="text-lg  text-white">
                We value imagination and cognitive processes because they allow
                us to concentrate on our internal monologue while also allowing
                us to engage in critical thinking. There are no good or bad
                ideas until we talk about their full potential and significance.
              </p>
            </div>
            <div className="card2 rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-[#2D3450]">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-4 h-4 mr-2 text-xl font-bold text-blue-300  rounded">
                  2
                </span>
                <p className="text-3xl font-bold text-blue-300  sm:text-xl">
                  Understanding
                </p>
              </div>
              <p className="text-lg  text-white">
                Before we can come up with a solution, we must first consider
                how and why it will be implemented. This is when our team's
                experience comes into play, and they make judgments based on
                knowledge. Understanding the project and its client's needs is
                essential.
              </p>
            </div>
            <div className="card2 rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-[#2D3450]">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-4 h-4 mr-2 text-xl font-bold text-blue-300 rounded">
                  3
                </span>
                <p className="text-3xl font-bold text-blue-300 sm:text-xl">
                  Cooperation
                </p>
              </div>
              <p className="text-lg text-white">
                There is no work without teamwork! We believe the way we
                approach the projects and the final result is a showcase of our
                team's behavior and cooperation. The way we approach projects
                and the end outcome, we feel, is a reflection of our team's
                conduct and collaboration.
              </p>
            </div>
            <div className="card2 rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-[#2D3450]">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-4 h-4 mr-2 text-xl font-bold rounded text-blue-300 ">
                  4
                </span>
                <p className="text-3xl font-bold text-blue-300  sm:text-xl">
                  Creation
                </p>
              </div>
              <p className="text-lg text-white">
                The result of our concept, knowledge, collaboration, and hard
                work. Where our concept, knowledge, teamwork, and hard work have
                all come together in the end. Established core team values
                result in the creation of industry-standard solutions. By
                leveraging our internal expertise, we deliver outstanding
                solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <div className="mb-6 sm:mx-auto"></div>
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center sm:max-w-md lg:max-w-xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-6xl font-bold sm:pl-6 leading-none tracking-tight text-white sm:text-4xl lg:text-5xl md:mx-auto">
                Need more info?
              </h2>
              <p className="text-base text-white sm:text-center sm:pl-6 md:text-lg">
                Drop us a message!
              </p>
            </div>
            <div>
              <a
                href="mailto:info@aikate.hr"
                className="contactbutton2 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
